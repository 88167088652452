import { FirebaseRemoteConfigKey, LOCALES, MEOWZ2_1 } from 'root-constants'

export const getMeowzAbTestOptions = ({
  cohort,
  language,
  remoteConfigVariantValue,
}: {
  cohort: string
  language: string
  remoteConfigVariantValue: any
}): Record<string, string> => {
  if (language !== LOCALES.EN || cohort !== MEOWZ2_1) {
    return {}
  }

  return {
    [FirebaseRemoteConfigKey.ABTEST_WOOBE_1220_MEOWZ_FIRST_PAGE]:
      remoteConfigVariantValue,
  }
}
