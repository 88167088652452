import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  selectCurrencySign,
  selectFullPrice,
  selectSupplyTitle,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmountVariantCs: React.FC = () => {
  const supplyTitle = useSelector(selectSupplyTitle)
  const trialCurrentPrice = useSelector(selectTrialFullPrice)
  const selectedPrice = useSelector(selectFullPrice)
  const currency = useSelector(selectCurrencySign)
  const { t } = useTranslation()

  return (
    <S.TotalPrice>
      {t`payment.total`}:&nbsp;
      <strong>
        {currency}
        {trialCurrentPrice || selectedPrice}
      </strong>
      &nbsp;for&nbsp;
      {supplyTitle}
    </S.TotalPrice>
  )
}
