import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { quizApi, userApi } from 'api'
import { TAnswer, TAnswers, TUtmTags } from 'models/common.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'
import {
  setAnswersAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/common/common-actions'
import { selectUUID } from 'root-redux/user/user-selects'
import { AnyAction } from '@reduxjs/toolkit'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'
import { eventLogger } from 'services/eventLogger-service'
import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants'
import { getCookie } from 'helpers/getCookie'
import { PageId } from '../../constants/pages'

const MODULE_NAME = 'USER'

export const SET_USER_UUID = `${MODULE_NAME}/SET_USER_UUID`
export const CREATE_UUID = `${MODULE_NAME}/CREATE_UUID`
export const SET_SECRET = `${MODULE_NAME}/SET_SECRET`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SET_EMAIL = `${MODULE_NAME}/SET_EMAIL`
export const SET_ONBOARDING_ANSWERS = `${MODULE_NAME}/SET_ONBOARDING_ANSWERS`
export const SEND_ANSWER = `${MODULE_NAME}/SEND_ANSWER`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const RESET_ANSWERS = `${MODULE_NAME}/RESET_ANSWERS`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SET_PHONE`
const BIND_USER = `${MODULE_NAME}/BIND_USER`
const SEND_USER_TTCLID = `${MODULE_NAME}/SEND_USER_TTCLID`

export function setUserUuidAction(uuid: string): IAction<string> {
  return {
    type: SET_USER_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setUserEmailAction(email: string): IAction<string> {
  return {
    type: SET_EMAIL,
    payload: email,
  }
}

export function setAllAnswersAction(answers: TAnswers): AnyAction {
  return {
    type: SET_ONBOARDING_ANSWERS,
    payload: answers,
  }
}

export const sendFacebookParamsAction =
  (uuid: string): any =>
  async (dispatch: TAppDispatchThunk<any>): Promise<void> => {
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')
    const creationTime = new Date().getTime()
    const randomNumber = Math.floor(Math.random() * 1e10)
    const urlParams = new URLSearchParams(window.location.search)
    const fbclid = urlParams.get('fbclid')

    const formattedFbc = !fbc && fbclid ? `fb.1.${creationTime}.${fbclid}` : fbc
    const formattedFbp = !fbp ? `fb.1.${creationTime}.${randomNumber}` : fbp

    dispatch(startFetching(SEND_FACEBOOK_PARAMS))

    const response = await userApi.saveFacebookParams({
      uuid,
      fbp: formattedFbp,
      fbc: formattedFbc,
    })

    if (!response.success) {
      dispatch(setErrorAction('Something went wrong.'))
    }

    dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
  }

export const createLeadAction =
  ({
    uuidFromUrl,
    utmTags,
    giaApiKeys,
    cohort,
    language,
  }: {
    uuidFromUrl: string | null
    utmTags: TUtmTags
    giaApiKeys: Record<string, string>
    cohort: string
    language: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore

    if (uuid) {
      dispatch(setUserUuidAction(uuid))
      return
    }

    dispatch(startFetching(CREATE_UUID))

    const response = await userApi.createLead(
      cohort,
      giaApiKeys[language],
      language,
      utmTags,
    )

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value

      dispatch(setUserUuidAction(responseUUID))
      dispatch(sendFacebookParamsAction(responseUUID))
    } else {
      console.error('error')
    }

    dispatch(stopFetching(CREATE_UUID))
  }

export function getUserStatusAction(uuid: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)

    if (response.success && response.data) {
      const { status, state: appState } = response.data
      const userStatus = getUserStatusFromRawUserStatus(status, appState)

      dispatch(setUserStatusAction(userStatus))
      dispatch(setAllAnswersAction(userStatus.onboarding))
      dispatch(setUserEmailAction(userStatus.email))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const saveUserAnswer =
  ({
    question,
    answers,
    pageNumber,
    pageName,
    answersToStore,
  }: {
    question: string
    answers: TAnswer
    pageNumber: number
    pageName: PageId
    answersToStore?: TAnswer
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_ANSWER))

    const response = await quizApi.sendAnswer({
      uuid,
      isFinished: false,
      isSkipped: false,
      question: pageName,
      answers: answersToStore || answers,
    })

    dispatch(
      setAnswersAction({
        question,
        answers,
        pageNumber,
        pageName,
      }),
    )

    if (!response.success) {
      dispatch(
        setErrorAction(
          'Something went wrong. Please check the address and try again',
        ),
      )
    }

    dispatch(stopFetching(SEND_ANSWER))
  }

export const sendUserEmailAction =
  ({
    email,
    uuid,
    successCallback,
    unsuccessCallback,
  }: {
    email: string
    uuid: string
    successCallback: () => void
    unsuccessCallback: () => void
  }): any =>
  async (dispatch: TAppDispatchThunk<any>): Promise<void> => {
    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({ email, uuid })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction('You already have an account.'))
      } else {
        dispatch(
          setErrorAction(
            'Something went wrong. Please check the address and try again',
          ),
        )
      }

      eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })

      unsuccessCallback()
      dispatch(stopFetching(SEND_USER_EMAIL))
      return
    }

    eventLogger.logEmailPageCompleted({ email })

    window.fbq('track', 'Lead', {}, { eventID: uuid })
    window.ttq.identify({ email })
    window.ttq.track('CompleteRegistration')
    window.snaptr('track', 'SIGN_UP', {
      user_email: email,
    })
    successCallback()
    dispatch(setUserEmailAction(email))
    dispatch(stopFetching(SEND_USER_EMAIL))
  }

export function bindUserAction(
  token: string,
  successCallback: () => void,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.signUpUser({
      token,
      uuid,
    })

    if (response.success && response.data) {
      response.data.custom_token &&
        localStorage.setItem(
          CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
          response.data.custom_token,
        )

      const userStatusResponse = await userApi.getUserStatus(uuid)

      const { status, state: appState } = userStatusResponse.data
      const userStatus = getUserStatusFromRawUserStatus(status, appState)

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({ method: userStatus.account.loginMethod })
      successCallback()
    } else {
      const errorMessage = response?.data.error || ''
      eventLogger.logAccountCreationFailed({
        error: errorMessage,
      })

      if (response.status === 409) {
        const message = errorMessage.toLowerCase().includes('subscription')
          ? 'You already have an email. Your subscription was refunded.'
          : 'You already have an account.'

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction('Something went wrong.'))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export const sendUserTiktokClidAction =
  (ttclid: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_TTCLID))

    const response = await userApi.saveUserTTCLID({ ttclid, uuid })

    if (!response.success) {
      dispatch(setErrorAction('Something went wrong.'))
    }

    dispatch(stopFetching(SEND_USER_TTCLID))
  }
