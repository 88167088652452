import { IRequestOptions, IResponseResult } from 'models/api.model'
import { ApiService } from 'services/api/api-service'
import { IRawVariant } from 'models/variant.model'
import { RequestMethod } from 'services/api/api-types'
import { IConfigRaw } from 'models/config.model'

export class CohortApi {
  private readonly api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getVariant(cohort: string): Promise<IResponseResult<IRawVariant>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { cohort },
    }

    return this.api.makeRequest<IRawVariant>(`v1/flow/meowz/variants`, options)
  }

  getConfig(): Promise<IResponseResult<IConfigRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IConfigRaw>(`v1/flow/meowz/config`, options)
  }
}
