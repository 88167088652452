import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIsCancelOfferApplied } from 'root-redux/common/common-selects'
import {
  selectCurrencySign,
  selectTrialDurationDays,
  selectTrialFullPrice,
  selectTrialOldFullPrice,
} from 'root-redux/payment/payment-selects'
import { getCustomPlanPrices } from 'helpers/getCustomPlanPrices'
import { CURRENCY } from 'root-constants'
import { StyledTotalAmount as S } from './TotalAmountVariant3.styles'

export const TotalAmountVariant3: React.FC = () => {
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const trialFullPrice = useSelector(selectTrialFullPrice)
  const trialOldFullPrice = useSelector(selectTrialOldFullPrice)
  const currency = useSelector(selectCurrencySign)

  const isDynamicPrices = useMemo(() => currency !== CURRENCY.USD, [currency])

  const planPrices = useMemo(
    () => getCustomPlanPrices(isCancelOfferApplied),
    [isCancelOfferApplied],
  )

  const isTrial = useMemo(() => trialPeriodDays === 7, [trialPeriodDays])

  const discountPercent = useMemo(
    () => (isCancelOfferApplied ? '71%' : '61%'),
    [isCancelOfferApplied],
  )

  const formattedFullPrice = useMemo(
    () =>
      isDynamicPrices ? trialFullPrice : planPrices[trialPeriodDays]?.fullPrice,
    [isDynamicPrices, trialFullPrice, trialPeriodDays],
  )
  const formattedOldFullPrice = useMemo(
    () =>
      isDynamicPrices
        ? trialOldFullPrice
        : planPrices[trialPeriodDays]?.oldFullPrice,
    [isDynamicPrices, trialOldFullPrice, trialPeriodDays],
  )

  const getDiscountAmount = useMemo(
    () => (formattedOldFullPrice - formattedFullPrice).toFixed(2),
    [formattedOldFullPrice, formattedFullPrice],
  )

  return (
    <S.TotalPriceWrapper>
      <S.TotalPriceContent>
        <S.TotalPriceItem>
          <p>
            {isTrial
              ? 'Regular 1-week trial price'
              : `Regular ${trialPeriodDays / 7}-week price`}
          </p>
          <strong>
            {currency}
            {formattedOldFullPrice}
          </strong>
        </S.TotalPriceItem>
        <S.TotalPriceItem>
          {discountPercent} introductory offer discount
          <S.DiscountAmount>
            -{currency}
            {getDiscountAmount}
          </S.DiscountAmount>
        </S.TotalPriceItem>
      </S.TotalPriceContent>
      <S.TotalWrapper>
        <p>Total today</p>
        <p>
          {currency}
          {formattedFullPrice}
        </p>
      </S.TotalWrapper>
      <S.TotalDiscountInfo>
        <S.DiscountAmount>
          🔥 You just saved {currency}
          {getDiscountAmount} ({discountPercent}
          &nbsp;off)
        </S.DiscountAmount>
      </S.TotalDiscountInfo>
    </S.TotalPriceWrapper>
  )
}
