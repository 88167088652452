import React from 'react'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import cat from 'assets/images/plan-cat.png'
import { Trans, useTranslation } from 'react-i18next'
import { StyledInterestingFact as S } from './InterestingFact.styles'

export const InterestingFact: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Wrapper id={SUBSCRIPTION_SECTION_IDS.interestingFact}>
      <S.Label>💡{t`onboarding.interestingFact.label`}</S.Label>
      <S.Title>
        <Trans
          i18nKey="onboarding.interestingFact.title"
          components={[<br />]}
        />
      </S.Title>
      <S.Description>
        {t`onboarding.interestingFact.description`} 😺🐾
      </S.Description>
      <S.Image src={cat} alt="cat" />
    </S.Wrapper>
  )
}
