import React from 'react'
import { useTranslation } from 'react-i18next'
import { IMainPrices } from 'models/suscriptions.model'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export interface ISelectPlanItemProps {
  subscription: IMainPrices
  currency: string
  isSelected: boolean
  isNonPayerFlow?: boolean
  isDefault: boolean
  id: string
}

export const PlanItem: React.FC<ISelectPlanItemProps> = ({
  subscription: { periodQuantity, weekly, oldPrices },
  currency,
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.PlanTitle>
        {periodQuantity}&nbsp;month{periodQuantity !== 1 ? 's' : ''}
      </S.PlanTitle>
      <S.PricesWrapper>
        <S.OldPrice>
          {currency}
          {oldPrices.weekly}
        </S.OldPrice>
        <strong>
          {currency}
          {weekly}
        </strong>{' '}
        / week
      </S.PricesWrapper>
    </S.PlanItem>
  )
}
