import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TimeInterval } from 'root-constants'
import { DAYS_IN_PERIOD } from 'modules/subscriptions/constants'
import { useSelector } from 'react-redux'
import { selectIsCancelOfferApplied } from 'root-redux/common/common-selects'
import { getCustomPlanPrices } from 'helpers/getCustomPlanPrices'
import { FlowContext } from 'components/FlowContext'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export const PlanItemVariant3: React.FC<ITrialSelectPlanItemProps> = ({
  subscription: { durationDays, daily },
  currency,
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t } = useTranslation()
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const { currentPageId } = useContext(FlowContext)

  const planPrices = useMemo(
    () => getCustomPlanPrices(isCancelOfferApplied, currentPageId),
    [isCancelOfferApplied],
  )

  return (
    <S.PlanItem
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.PlanTitle>
        {durationDays === DAYS_IN_PERIOD[TimeInterval.WEEK] ? (
          <>
            {durationDays / 7}-Week Trial
            <S.TrialBadge>4-WEEK PLAN</S.TrialBadge>
          </>
        ) : (
          `${durationDays / 7}-Week Plan`
        )}
        <S.OldPricesWrapper>
          <S.StartOldPrice>
            {currency}
            {planPrices[durationDays].oldFullPrice}
          </S.StartOldPrice>
          <S.CurrentPrice>
            {currency}
            {planPrices[durationDays].fullPrice}
          </S.CurrentPrice>
        </S.OldPricesWrapper>
      </S.PlanTitle>
      <S.PricesWrapper>
        {daily && (
          <S.PricePerPeriod isSelected={isSelected} isDefault={isDefault}>
            <S.OldIntervalPrice>
              {currency}
              {planPrices[durationDays].oldDailyPrice}
            </S.OldIntervalPrice>
            <strong>
              {currency}
              {planPrices[durationDays].dailyPrice}
            </strong>
            {t`onboarding.planBlock.per`}&nbsp;
            {t`onboarding.planBlock.day`}
          </S.PricePerPeriod>
        )}
      </S.PricesWrapper>
    </S.PlanItem>
  )
}
