import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  AMPLITUDE_API_KEY,
  CurrentEnvironment,
  EventLoggerInstanceName,
} from 'root-constants'
import { Amplitude } from 'services/amplitude-service'
import { UserFlowTracker } from 'services/userFlowTracker-service'
import { eventLogger, Events } from 'services/eventLogger-service'
import { addAmplitudeLogger } from 'helpers/addAmplitudeLogger'
import {
  selectFirebaseRemoteConfig,
  selectGiaApiKeys,
  selectIsLoggerInitialized,
  selectLanguage,
} from 'root-redux/common/common-selects'
import { selectUUID } from 'root-redux/user/user-selects'
import { setIsLoggerInitialized } from 'root-redux/common/common-actions'
import { TAppDispatch } from 'root-redux/store'
import { getMeowzAbTestOptions } from 'helpers/getMeowzAbTestOptions'
import { getSegmentsFromFirebaseConfig } from 'helpers/getSegmentsFromFirebaseConfig'
import { useGetRemoteConfigVariantData } from './useGetRemoteConfigVariantData'

function getGIAAmplitudeOptions() {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint:
      getCurrentEnv() === 'prod'
        ? 'analytics.gismart.xyz/events'
        : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
  }
}

export function useEventLoggerInitialization({
  cohort,
}: {
  cohort: string
}): void {
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const language = useSelector(selectLanguage)
  const { search } = useLocation()
  const isLoggerInitialized = useSelector(selectIsLoggerInitialized)
  const firebaseRemoteConfig = useSelector(selectFirebaseRemoteConfig)
  const remoteConfigVariantValue = useGetRemoteConfigVariantData()

  const URLParams = new URLSearchParams(search)
  const utmTags = Object.fromEntries(URLParams.entries())
  const isProdEnvironment = getCurrentEnv() === CurrentEnvironment.PROD

  useEffect(() => {
    if (
      isLoggerInitialized ||
      !uuid ||
      !giaApiKeys ||
      !language ||
      !firebaseRemoteConfig
    ) {
      return
    }

    const giaService = new Amplitude({
      apiKey: giaApiKeys[language],
      instanceName: EventLoggerInstanceName.GIA,
      options: getGIAAmplitudeOptions(),
      userId: uuid,
      utmTags,
      isProdEnvironment,
    })

    const userFlowTracker = new UserFlowTracker({
      instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
      userId: uuid,
    })
    const meowzAbTestOptions = getMeowzAbTestOptions({
      cohort,
      language,
      remoteConfigVariantValue,
    })

    giaService.configure({
      cohort,
      options: meowzAbTestOptions || {},
    })
    userFlowTracker.configure({ cohort })

    const segments = getSegmentsFromFirebaseConfig(firebaseRemoteConfig)

    eventLogger.init([giaService, userFlowTracker])

    if (segments.length) {
      segments.map((segment) =>
        giaService.log({
          event: Events.AB_SEGMENT,
          eventProperty: {
            ab_segment_name: segment,
          },
        }),
      )
    }

    addAmplitudeLogger({
      amplitudeApiKey: AMPLITUDE_API_KEY,
      cohort,
      URLSearchString: search,
      uuid,
      language,
      firebaseRemoteConfig,
    })

    dispatch(setIsLoggerInitialized(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, giaApiKeys])
}
