import firebase from 'firebase/compat/app'

export const getSegmentsFromFirebaseConfig = (
  firebaseRemoteConfig: firebase.remoteConfig.RemoteConfig | null,
) => {
  const segments: string[] = []

  if (firebaseRemoteConfig) {
    for (const property in firebaseRemoteConfig) {
      if (property.startsWith('abtest_')) {
        segments.push(firebaseRemoteConfig[property]._value)
      }
    }
  }

  return segments
}
