import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  selectCurrencySign,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmount: React.FC = () => {
  const trialCurrentPrice = useSelector(selectTrialFullPrice)
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const selectedPrice = useSelector(selectFullPrice)
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const currency = useSelector(selectCurrencySign)
  const { t } = useTranslation()

  const periodNameFormatted = useMemo(() => {
    if (!periodName) return ''
    return t(`onboarding.planBlock.${periodName}`)
  }, [periodName])

  return (
    <S.TotalPrice>
      {t`payment.total`}:&nbsp;
      <strong>
        {currency}
        {trialCurrentPrice || selectedPrice}
      </strong>
      &nbsp;
      {trialCurrentPrice ? (
        <Trans i18nKey="payment.forTrial" values={{ trialPeriodDays }} />
      ) : (
        <Trans
          i18nKey="payment.forPriceTotal"
          values={{ periodQuantity, periodNameFormatted }}
        />
      )}
    </S.TotalPrice>
  )
}
