/* eslint-disable max-lines */
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { CENTS_IN_DOLLAR, PLAN_TYPES } from 'root-constants'
import { selectEmail, selectUUID } from 'root-redux/user/user-selects'
import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/common/common-actions'
import {
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { getSubscriptionListFromRawData } from 'root-redux/payment/helpers/getSubscriptionListFromRawData'
import { ISubscription } from 'models/suscriptions.model'
import { eventLogger } from 'services/eventLogger-service'
import { getPaymentConfigFromRawData } from 'root-redux/payment/helpers/getPaymentConfigFromRawData'
import { IPaymentConfig } from 'models/payment.model'
import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentSystem,
} from 'modules/payment/constants'
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import {
  selectCurrency,
  selectFullPrice,
  selectPaymentClientSecret,
  selectPeriodName,
  selectPeriodQuantity,
  selectPlanId,
  selectProductPrice,
  selectStripeAccount,
  selectSubscriptionId,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { createProductId } from 'helpers/createProductId'
import { logSuccessfulPayment } from 'modules/payment/helpers/logSuccessfulPayment'
import { logFailedPayment } from 'modules/payment/helpers/logFailedPayment'
import { getRedirectUrl } from 'modules/payment/helpers/general'
import { IPaymentInfo } from 'modules/payment/types'
import { checkIsRetryAllowed } from 'modules/payment/helpers/checkIsRetryAllowed'
import { paymentApi } from 'api'
import { createProductName } from 'helpers/createProductName'
import { PageId } from '../../constants/pages'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const GET_SUBSCRIPTIONS_PLANS = `${MODULE_NAME}/GET_SUBSCRIPTIONS_PLANS`
export const SET_SUBSCRIPTION_PLANS = `${MODULE_NAME}/SET_SUBSCRIPTION_PLANS`
export const SET_PLANS_WAS_LOADED = `${MODULE_NAME}/SET_PLANS_WAS_LOADED`
export const GET_PAYMENT_CONFIG = `${MODULE_NAME}/GET_PAYMENT_CONFIG`
export const SET_PAYMENT_CONFIG = `${MODULE_NAME}/SET_PAYMENT_CONFIG`
export const SET_IS_CANCEL_OFFER_APPLIED = `${MODULE_NAME}/SET_IS_CANCEL_OFFER_APPLIED`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const SET_PAYMENT_SYSTEM = `${MODULE_NAME}/SET_PAYMENT_SYSTEM`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_INFO = `${MODULE_NAME}/SET_PAYMENT_INFO`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_SUCCESSFUL_PAYPAL_PAYMENT = `${MODULE_NAME}/SET_SUCCESSFUL_PAYPAL_PAYMENT`
export const SET_SUPPLY_TITLE = `${MODULE_NAME}/SET_SUPPLY_TITLE`

export function setSubscriptionListAction(
  payload: ISubscription[],
): IAction<ISubscription[]> {
  return {
    type: SET_SUBSCRIPTION_PLANS,
    payload,
  }
}

export const setSubscriptionsPlansWasLoadedAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_PLANS_WAS_LOADED,
  payload,
})

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const setPaymentSystemAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_SYSTEM,
  payload,
})

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

export const setSuccessfulPaypalPayment = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_SUCCESSFUL_PAYPAL_PAYMENT,
  payload,
})

const setPaymentInfoAction = (
  payload: IPaymentInfo,
): IAction<IPaymentInfo> => ({
  type: SET_PAYMENT_INFO,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export function getSubscriptionPlans(
  type: PLAN_TYPES,
  tags?: string[],
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectVariantCohort(state)

    dispatch(startFetching(GET_SUBSCRIPTIONS_PLANS))

    const response = await paymentApi.getSubscriptionsList({
      type,
      uuid,
      tags,
      cohort,
    })

    if (response.success && response.data) {
      const config = getSubscriptionListFromRawData(response.data.plans)
      dispatch(setSubscriptionListAction(config))
    } else {
      dispatch(setSubscriptionsPlansWasLoadedAction(false))
    }

    dispatch(stopFetching(GET_SUBSCRIPTIONS_PLANS))
  }
}

export function setPaymentConfigAction(
  payload: IPaymentConfig,
): IAction<IPaymentConfig> {
  return {
    type: SET_PAYMENT_CONFIG,
    payload,
  }
}

export function setIsCancelOfferAppliedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_CANCEL_OFFER_APPLIED,
    payload,
  }
}

export function setSupplyTitle(payload: string): IAction<string> {
  return {
    type: SET_SUPPLY_TITLE,
    payload,
  }
}

export function getPaymentConfigAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectVariantCohort(state)

    dispatch(startFetching(GET_PAYMENT_CONFIG))

    const response = await paymentApi.getPaymentConfig({
      uuid,
      cohort,
    })

    if (response.success && response.data) {
      const config = getPaymentConfigFromRawData(response.data)

      eventLogger.updateAmplitudeUserProperties({
        stripe_account_id: config.stripe.accountId,
        stripe_account_name: config.stripe.accountName,
      })

      dispatch(setPaymentConfigAction(config))
    }

    dispatch(stopFetching(GET_PAYMENT_CONFIG))
  }
}

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
    screenName,
    currency = 'USD',
  }: {
    stripe: Stripe
    paymentPageId: PageId
    card?: StripeCardNumberElement
    name?: string
    screenName?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
    currency?: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const productPrice = selectProductPrice(state)
    const currentPrice = selectFullPrice(state)
    const introPrice = selectTrialFullPrice(state)
    const introQuantity = selectTrialDurationDays(state)
    const trialPeriodPrice = selectTrialFullPrice(state)
    const trialPeriodDays = selectTrialDurationDays(state)
    const periodName = selectPeriodName(state)
    const periodQuantity = selectPeriodQuantity(state)
    const optimizeVariantId = selectOptimizeVariantId(state)
    const optimizeExperimentId = selectOptimizeExperimentId(state)
    const optimizeSegmentName = selectOptimizeSegmentName(state)
    const email = selectEmail(state)
    const cohort = selectVariantCohort(state)
    const stripeAccount = selectStripeAccount(state)
    const planId = selectPlanId(state)

    if (!currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    dispatch(startFetching(PURCHASE))

    const productId = createProductId({
      periodName,
      periodQuantity,
      price: currentPrice,
    })
    const productName = createProductName({
      periodName,
      periodQuantity,
      price: currentPrice,
      introPrice,
      introQuantity,
      currency,
    })

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    eventLogger.logPurchaseStarted({
      productId,
      productName,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      email,
      cohort,
      stripeAccount,
      screenName,
    })
    window.ttq.identify({ email })
    window.ttq.track('AddPaymentInfo')
    window.snaptr('track', 'ADD_BILLING')

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: {
                name: name || DEFAULT_CARDHOLDER_NAME,
                email: email || DEFAULT_CARDHOLDER_NAME,
              },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, code, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          productId,
          productName,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodPrice,
            currency,
          },
          error: {
            type,
            code,
            description: message,
          },
          paymentMethod,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          screenName,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const createSubscriptionResponse =
        await paymentApi.createStripeSubscription({
          uuid,
          cohort,
          trialPeriodDays,
          planId,
        })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        if (createSubscriptionResponse.status === 404) {
          dispatch(setErrorAction('Error: Link is not valid'))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        if (createSubscriptionResponse.status === 409) {
          dispatch(setErrorAction('Error: You already have a email'))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(setErrorAction('Error: Something went wrong'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentInfoAction({
          paymentClientSecret:
            createSubscriptionResponse.data.purchase.client_secret,
          trialPeriodDays:
            createSubscriptionResponse.data.purchase.trial_period_days,
          subscriptionId:
            createSubscriptionResponse.data.purchase.subscription_id,
        }),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          productId,
          productName,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          screenName,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          stripeError: cardPaymentResponseFirst.error as StripeError,
          uuid,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentInfoAction({
            paymentClientSecret:
              retryResponseFirst.data.subscription.client_secret,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
          }),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          logSuccessfulPayment({
            price:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            productId,
            productName,
            trialPrice: trialPeriodPrice,
            uuid,
            periodName,
            periodQuantity,
            optimizeExperimentId,
            optimizeVariantId,
            optimizeSegmentName,
            email,
            cohort,
            screenName,
            productPrice,
            stripeAccount,
            currency,
          })

          dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          productId,
          productName,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          currency,
          screenName,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          stripeError: cardPaymentResponseSecond.error as StripeError,
          uuid,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentInfoAction({
            paymentClientSecret:
              retryResponseSecond.data.subscription.client_secret,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
          }),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          logSuccessfulPayment({
            price:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            productId,
            productName,
            trialPrice: trialPeriodPrice,
            uuid,
            periodName,
            periodQuantity,
            optimizeExperimentId,
            optimizeVariantId,
            optimizeSegmentName,
            email,
            cohort,
            screenName,
            productPrice,
            stripeAccount,
            currency,
          })

          dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          productId,
          productName,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          currency,
          screenName,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          stripeError: cardPaymentResponseThird.error as StripeError,
          uuid,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )

          dispatch(stopFetching(PURCHASE))

          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentInfoAction({
            paymentClientSecret:
              retryResponseThird.data.subscription.client_secret,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
          }),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          logSuccessfulPayment({
            price:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            productId,
            productName,
            trialPrice: trialPeriodPrice,
            uuid,
            periodName,
            periodQuantity,
            optimizeExperimentId,
            optimizeVariantId,
            optimizeSegmentName,
            email,
            cohort,
            screenName,
            productPrice,
            stripeAccount,
            currency,
          })

          dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          productId,
          productName,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          currency,
          screenName,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          stripeError: cardPaymentResponseFourth.error as StripeError,
          uuid,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          productId,
          productName,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodPrice,
            currency,
          },
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          stripeAccount,
          email,
          screenName,
        })
        dispatch(setErrorAction('Payment failed'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      logSuccessfulPayment({
        productId,
        productName,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        uuid,
        periodName,
        periodQuantity,
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        email,
        cohort,
        screenName,
        productPrice,
        stripeAccount,
        currency,
      })

      dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const currentPrice = selectFullPrice(state)
    const trialPeriodPrice = selectTrialFullPrice(state)
    const periodName = selectPeriodName(state)
    const productPrice = selectProductPrice(state)
    const periodQuantity = selectPeriodQuantity(state)
    const introPrice = selectTrialFullPrice(state)
    const introQuantity = selectTrialDurationDays(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectTrialDurationDays(state)
    const subscriptionId = selectSubscriptionId(state)
    const uuid = selectUUID(state)
    const optimizeVariantId = selectOptimizeVariantId(state)
    const optimizeExperimentId = selectOptimizeExperimentId(state)
    const optimizeSegmentName = selectOptimizeSegmentName(state)
    const email = selectEmail(state)
    const cohort = selectVariantCohort(state)
    const stripeAccount = selectStripeAccount(state)
    const currency = selectCurrency(state)

    if (!currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const productId = createProductId({
      periodName,
      periodQuantity,
      price: currentPrice,
    })
    const productName = createProductName({
      periodName,
      periodQuantity,
      price: currentPrice,
      introPrice,
      introQuantity,
      currency,
    })

    const response = await stripe.retrievePaymentIntent(clientSecret)

    if (response.paymentIntent?.status === 'succeeded') {
      logSuccessfulPayment({
        price: currentPrice,
        productId,
        productName,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        subscriptionId,
        uuid,
        periodName,
        periodQuantity,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        email,
        cohort,
        productPrice,
        stripeAccount,
        currency,
      })

      dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
      dispatch(reset3DSecureIframeUrlAction())
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        productId,
        productName,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type: 'requires_payment_method',
        },
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, code, message },
      } = response

      eventLogger.logPurchaseFailed({
        productId,
        productName,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type,
          code,
          description: message,
        },
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }
