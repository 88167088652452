import { IResponseResult, IRequestOptions } from 'models/api.model'
import {
  IInAppResponseRaw,
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
  IPurchaseStripeResponseRaw,
} from 'models/payment.model'
import { ApiService } from 'services/api/api-service'
import { IRawSubscription } from 'models/suscriptions.model'
import { RequestMethod } from 'services/api/api-types'
import { StripeError } from '@stripe/stripe-js'

export class PaymentApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getPaymentConfig({
    uuid,
    cohort,
  }: {
    uuid: string
    cohort: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { uuid },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `v1/flow/meowz/variant/${cohort}/payment-config`,
      options,
    )
  }

  getSubscriptionsList({
    uuid,
    cohort,
    tags = ['no_tax'],
    type,
  }: {
    uuid: string
    cohort: string
    type: string
    tags?: string[]
  }): Promise<IResponseResult<{ plans: IRawSubscription }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        plan_type: type,
        uuid,
        tags: tags.join(','),
      },
    }

    return this.api.makeRequest<{ plans: IRawSubscription }>(
      `v2/plans/meowz/${cohort}`,
      options,
    )
  }

  createStripeSubscription({
    uuid,
    trialPeriodDays,
    cohort,
    planId,
  }: {
    uuid: string
    trialPeriodDays: number
    cohort: string
    planId: number
  }): Promise<IResponseResult<IPurchaseStripeResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        trial_duration: trialPeriodDays,
        cohort,
        plan_id: planId,
      },
    }

    return this.api.makeRequest<IPurchaseStripeResponseRaw>(
      `v2/purchases/stripe/${uuid}/meowz`,
      options,
    )
  }

  retryPayment({
    uuid,
    stripeError,
  }: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `v2/purchases/stripe/${uuid}/meowz/retry`,
      options,
    )
  }

  createOneTimePayment({
    uuid,
    planId,
  }: {
    uuid: string
    planId: number
  }): Promise<IResponseResult<IInAppResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
      },
    }

    return this.api.makeRequest<IInAppResponseRaw>(
      `v2/purchases/${uuid}/meowz/inapp`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
    cohort,
  }: {
    uuid: string
    paypalPlanId: string | null
    cohort: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
        cohort,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `v2/purchases/paypal/${uuid}/meowz`,
      options,
    )
  }
}
