import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { COHORT_KEYS_MAP } from 'root-constants'
import {
  selectFirebaseRemoteConfig,
  selectVariantCohort,
} from 'root-redux/common/common-selects'

export const useGetRemoteConfigVariantData = (): string => {
  const cohort = useSelector(selectVariantCohort)
  const firebaseRemoteConfig = useSelector(selectFirebaseRemoteConfig)

  const remoteConfigVariantValue = useMemo(
    (): string => firebaseRemoteConfig[COHORT_KEYS_MAP[cohort]]?._value,
    [firebaseRemoteConfig, cohort],
  )

  return remoteConfigVariantValue
}
