import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventLogger } from 'services/eventLogger-service'
import {
  selectLanguage,
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { selectUUID } from 'root-redux/user/user-selects'
import { COHORT_KEYS_MAP, LOCALES, MEOWZ2_1 } from 'root-constants'
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { useGetRemoteConfigVariantData } from './useGetRemoteConfigVariantData'

export const useStartSession = (): void => {
  const optimizeVariantId = useSelector(selectOptimizeVariantId)
  const optimizeExperimentId = useSelector(selectOptimizeExperimentId)
  const optimizeSegmentName = useSelector(selectOptimizeSegmentName)
  const cohort = useSelector(selectVariantCohort)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const remoteConfigVariant = useGetRemoteConfigVariantData()

  useEffect(() => {
    if (cohort === MEOWZ2_1 && language === LOCALES.EN) {
      eventLogger.logSessionStarted({
        optimizeVariantId,
        optimizeExperimentId,
        optimizeSegmentName,
        cohort,
        abVariant: remoteConfigVariant
          ? COHORT_KEYS_MAP[cohort]
          : getCurrentOnboardingVariant(optimizeVariantId),
        abSegmentName: remoteConfigVariant || optimizeSegmentName,
      })

      return
    }

    eventLogger.logSessionStarted({
      optimizeVariantId,
      optimizeExperimentId,
      optimizeSegmentName,
      cohort,
    })
  }, [
    optimizeExperimentId,
    optimizeSegmentName,
    optimizeVariantId,
    cohort,
    language,
    remoteConfigVariant,
  ])

  useEffect(() => {
    if (uuid && window.fbq !== undefined) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
    }
  }, [uuid])
}
