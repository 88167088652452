import { IRequestOptions, IResponseResult } from 'models/api.model'
import { RequestMethod } from 'services/api/api-types'
import { ApiService } from 'services/api/api-service'
import { TUtmTags } from 'models/common.model'
import { ISignUpUserResponseRaw, IUserStatusRaw } from 'models/user.model'

export class UserApi {
  private readonly api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createLead(
    cohort: string,
    appId: string,
    locale: string,
    utmTags: TUtmTags,
  ): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        app_id: appId,
        locale,
        app_name: 'meowz',
        utm_tags: utmTags,
      },
    }

    return this.api.makeRequest<string>('v2/leads', options)
  }

  getUserStatus(uuid: string): Promise<IResponseResult<IUserStatusRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IUserStatusRaw>(
      `v2/leads/${uuid}/meowz`,
      options,
    )
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `v2/leads/${uuid}/meowz/facebook`,
      options,
    )
  }

  saveUserTTCLID({
    ttclid,
    uuid,
  }: {
    ttclid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ttclid },
    }
    return this.api.makeRequest(`v2/leads/${uuid}/tiktok`, options)
  }

  saveUserEmail({
    email,
    uuid,
  }: {
    uuid: string
    email: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `v2/leads/${uuid}/meowz/email`,
      options,
    )
  }

  signUpUser({
    token,
    uuid,
  }: {
    token: string
    uuid: string
  }): Promise<IResponseResult<ISignUpUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token,
      },
    }

    return this.api.makeRequest<ISignUpUserResponseRaw>(
      `v2/leads/${uuid}/meowz/signup`,
      options,
    )
  }
}
