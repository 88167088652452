import { IRequestOptions, IResponseResult } from 'models/api.model'
import { ApiService } from 'services/api/api-service'
import { IRawVariant } from 'models/variant.model'
import { TAnswer } from 'models/common.model'
import { RequestMethod } from 'services/api/api-types'

export class QuizApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  sendAnswer({
    uuid,
    isFinished,
    isSkipped,
    question,
    answers,
  }: {
    uuid: string
    isFinished?: boolean
    isSkipped?: boolean
    question: string
    answers: TAnswer
  }): Promise<IResponseResult<IRawVariant>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: {
          [question]: answers,
        },
        version: '1.0',
      },
    }

    return this.api.makeRequest<IRawVariant>(
      `v2/leads/${uuid}/meowz/quiz`,
      options,
    )
  }
}
