import { Option, OptionType } from 'components/option/Option'
import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import discount from 'assets/images/sprite/discount-label.svg'
import discount61 from 'assets/images/sprite/discount-label-61.svg'
import { ISubscription } from 'models/suscriptions.model'
import { selectSubscription } from 'root-redux/payment/payment-selects'
import { OutlinedArrowButton } from 'components/buttons/OutlinedArrowButton'
import { ISvg } from 'components/SvgImage'
import { useTranslation } from 'react-i18next'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { LOCALES, MEOWZ2_1_V4 } from 'root-constants'
import { StyledPlanBlock as S } from './PlanBlock.styles'

type TProps = {
  onButtonClick: () => void
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<React.PropsWithChildren<ITrialSelectPlanItemProps>>
  availableSubscriptions: ISubscription[]
  isNonPayerFlow: boolean
}

export const SelectPlanItemsBlock: React.FC<
  React.PropsWithChildren<TProps>
> = ({
  onButtonClick,
  onSelect,
  SelectPlanItem,
  availableSubscriptions,
  isNonPayerFlow,
}) => {
  const { t, i18n } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const cohort = useSelector(selectVariantCohort)

  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  const isSubscriptionSelected = useCallback(
    (subscription) => selectedSubscription?.id === subscription.id,
    [selectedSubscription?.id],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = availableSubscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, availableSubscriptions],
  )

  return (
    <S.SelectPlanCardVariant3 id={SUBSCRIPTION_SECTION_IDS.chooseMyPlan}>
      <S.CardTitleWhite>{t`commonComponents.chooseYourPlan`}</S.CardTitleWhite>
      <S.PlanItems>
        {availableSubscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SelectPlanItem
              currency={subscription.currencySign}
              subscription={subscription.trialPrices}
              id={subscription.id}
              isDefault={subscription.isDefault}
              isSelected={isSubscriptionSelected(subscription)}
              isNonPayerFlow={isNonPayerFlow}
            />
          </Option>
        ))}
      </S.PlanItems>
      <S.PlanButtonWrapper>
        <S.DiscountImage
          svg={cohort === MEOWZ2_1_V4 ? discount61 : (discount as ISvg)}
          isLongLocale={isLongLocale}
        />
        <OutlinedArrowButton
          onClick={onButtonClick}
          text={t`commonComponents.getMyPlan`}
        />
      </S.PlanButtonWrapper>
    </S.SelectPlanCardVariant3>
  )
}
