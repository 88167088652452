import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { Trans, useTranslation } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'
import { TIME_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'How much time during the day can you spend training?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="onboarding.training.question" components={[<br />]} />
        </S.Title>

        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            value={TIME_OPTIONS_VALUES.TEN_FIFTEEN_MINUTES}
          >
            <QuestionButton>{t`onboarding.training.tenFifteenMinutes`}</QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={TIME_OPTIONS_VALUES.FIFTEEN_TWENTY_MINUTES}
          >
            <QuestionButton>{t`onboarding.training.fifteenTwentyMinutes`}</QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={TIME_OPTIONS_VALUES.TWENTY_THIRTY_MINUTES}
          >
            <QuestionButton>{t`onboarding.training.twentyThirtyMinutes`}</QuestionButton>
          </S.Option>
          <S.Option
            {...optionProps}
            value={TIME_OPTIONS_VALUES.MORE_THAN_THIRTY_MINUTES}
          >
            <QuestionButton>{t`onboarding.training.moreThanThirty`}</QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.TIME

export const TimeQuestion = Component
