import React, { useMemo } from 'react'
import guaranteeImage from 'assets/images/cat-guarantee.png'
import catBefore from 'assets/images/cat-before.jpg'
import catAfter from 'assets/images/cat-after.jpg'
import smile from 'assets/images/sprite/smile-emoji.svg'
import { ISvg } from 'components/SvgImage'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { CONTACT_FORM_LINKS, TERMS_OF_USE_LINKS } from 'root-constants'
import { Trans, useTranslation } from 'react-i18next'
import { StyledGuarantee as S } from './Guarantee.styles'

export const Guarantee: React.FC = () => {
  const { t } = useTranslation()

  const dateBefore = useMemo(() => {
    const currentDateBefore = new Date()
    return new Date(currentDateBefore.setDate(currentDateBefore.getDate() - 45))
  }, [])
  const dateAfter = useMemo(() => {
    const currentDateAfter = new Date()
    return new Date(currentDateAfter.setDate(currentDateAfter.getDate() - 7))
  }, [])
  const env = getCurrentEnv()

  return (
    <>
      <S.Wrapper>
        <S.CatImage src={guaranteeImage} />
        <S.Title>
          <Trans i18nKey="onboarding.guarantee.title" components={[<br />]} />
        </S.Title>
        <S.Text>
          {t`onboarding.guarantee.text`}&nbsp;
          <S.InnerLink target="_blank" to={TERMS_OF_USE_LINKS[env]}>
            {t`commonComponents.termsOfUse`}
          </S.InnerLink>
          &nbsp;
          {t`commonComponents.contact`}&nbsp;
          <S.InnerLink target="_blank" to={CONTACT_FORM_LINKS[env]}>
            {t`commonComponents.support`}
          </S.InnerLink>
          .
        </S.Text>
      </S.Wrapper>
      <S.ResultsBlock>
        <S.Title>
          <Trans
            i18nKey="onboarding.guarantee.resultsOfUser"
            components={[<br />, <strong />]}
          />
        </S.Title>
        <S.ResultsWrapper>
          <S.ImageWrapper>
            <S.ResultImage src={catBefore} />
            <S.LabelBefore>
              {dateBefore.toLocaleDateString('en-US')}
            </S.LabelBefore>
          </S.ImageWrapper>
          <S.ImageWrapper>
            <S.ResultImage src={catAfter} />
            <S.LabelAfter>{dateAfter.toLocaleDateString('en-US')}</S.LabelAfter>
            <S.Emoji svg={smile as ISvg} />
          </S.ImageWrapper>
        </S.ResultsWrapper>
      </S.ResultsBlock>
    </>
  )
}
