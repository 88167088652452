import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'

import calledCat from 'assets/images/sprite/called-cat.svg'
import lazyCat from 'assets/images/sprite/lazy-cat.svg'
import { PageId } from 'src/constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { Trans, useTranslation } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Does your cat come when called?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )
      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans
            i18nKey="onboarding.comeWhenCalled.question"
            components={[<br />]}
          />
        </S.Title>
        <S.OptionsContainer data-is-multiple>
          <S.Option
            {...optionProps}
            value={GENERAL_QUESTIONS_OPTIONS_VALUES.YES}
          >
            <S.QuestionButtonWithImg>
              <S.CatImage svg={calledCat} />
              <S.AnswerText>{t`onboarding.common.yes`}</S.AnswerText>
            </S.QuestionButtonWithImg>
          </S.Option>
          <S.Option
            {...optionProps}
            value={GENERAL_QUESTIONS_OPTIONS_VALUES.NO}
          >
            <S.QuestionButtonWithImg>
              <S.CatImage svg={lazyCat} />
              <S.AnswerText>{t`onboarding.common.no`}</S.AnswerText>
            </S.QuestionButtonWithImg>
          </S.Option>
        </S.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.COME_WHEN_CALLED

export const ComeWhenCalledQuestion = Component
