import React from 'react'
import laptop from 'assets/images/sprite/laptop-cat.svg'
import home from 'assets/images/sprite/home.svg'
import think from 'assets/images/sprite/emoji-think.svg'
import gain from 'assets/images/sprite/gain.svg'
import angryCatImg from 'assets/images/sprite/angry-cat-emoji.svg'
import emoji from 'assets/images/sprite/emoji-clever.svg'
import cat from 'assets/images/sprite/cat-emoji.svg'
import catInLove from 'assets/images/sprite/cat-in-love-emoji.svg'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISvg, SvgImage } from 'components/SvgImage'
import { Trans, useTranslation } from 'react-i18next'
import { StyledObtainingList as S } from './ObtainingList.styles'

export const ObtainingListVariant2: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Wrapper id={SUBSCRIPTION_SECTION_IDS.obtaining}>
      <S.Title>{t`onboarding.obtainingList.question`}</S.Title>
      <S.ListsWrapper>
        <S.ListItem>
          <SvgImage svg={laptop as ISvg} width={48} />
          {t`onboarding.obtainingList.item1`}
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={catInLove as ISvg} width={48} />
          {t`onboarding.obtainingList.item2`}
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={angryCatImg as ISvg} width={48} />
          {t`onboarding.obtainingList.item3`}
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={think as ISvg} width={48} />
          {t`onboarding.obtainingList.item4`}
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={gain as ISvg} width={48} />
          {t`onboarding.obtainingList.item5`}
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={cat as ISvg} width={48} />
          <Trans
            i18nKey="onboarding.obtainingList.item6"
            components={[<br />]}
          />
        </S.ListItem>
        <S.ListItem>
          <SvgImage svg={home as ISvg} width={48} />
          {t`onboarding.obtainingList.item7`}
        </S.ListItem>

        <S.ListItem>
          <SvgImage svg={emoji as ISvg} width={48} />
          {t`onboarding.obtainingList.item8`}
        </S.ListItem>
      </S.ListsWrapper>
    </S.Wrapper>
  )
}
