import paymentServicesImage from 'assets/images/payment-services.png'
import { FlowContext } from 'components/FlowContext'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsCancelOfferApplied,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import {
  selectCurrency,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { selectEmail } from 'root-redux/user/user-selects'
import { resetErrorAction } from 'root-redux/common/common-actions'
import { eventLogger, ScreenName } from 'services/eventLogger-service'
import { googleAnalyticsLogger } from 'services/googleAnalytics-service'
import { createProductId } from 'helpers/createProductId'
import { PaymentRequestOption } from 'modules/payment/components/payment-request-options/PaymentRequestOption'
import { useLocation } from 'react-router'
import { createProductName } from 'helpers/createProductName'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'
import { TotalAmountVariantCs } from './total-amount/TotalAmountVariantCs'
import { StripePaymentForm } from './stripe-payment-form/StripePaymentForm'

export const PaymentFlowsVariantCs: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { onPreviousStep } = useContext(FlowContext)
  const cohort = useSelector(selectVariantCohort)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const email = useSelector(selectEmail)
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const price = useSelector(selectFullPrice)
  const introPrice = useSelector(selectTrialFullPrice)
  const introQuantity = useSelector(selectTrialDurationDays)
  const currency = useSelector(selectCurrency)
  const screenName = useMemo(
    () => (isCancelOfferApplied ? ScreenName.CANCEL : ScreenName.SALES),
    [isCancelOfferApplied],
  )

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )
  const productName = useMemo(
    () =>
      createProductName({
        periodName,
        periodQuantity,
        price,
        introPrice,
        introQuantity,
        currency,
      }),
    [periodName, periodQuantity, price, introPrice, introQuantity, currency],
  )

  const handleBack = useCallback(() => {
    eventLogger.logPaymentMethodPageClose({
      cohort,
      email,
      productId,
      productName,
      screenName,
    })

    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)

    onPreviousStep()
  }, [
    cohort,
    email,
    screenName,
    dispatch,
    productId,
    productName,
    onPreviousStep,
  ])

  return (
    <>
      <S.TitleWrapper>
        <S.Title>{t`payment.checkout`}</S.Title>
        <S.BackButton2 onClick={handleBack} />
      </S.TitleWrapper>
      <TotalAmountVariantCs />
      <S.PaymentTitle>
        <span>{t`payment.paymentInfo`}</span>
        <S.PaymentTitleProtection>{t`payment.protected`}</S.PaymentTitleProtection>
      </S.PaymentTitle>
      <S.Form style={{ width: '100%', marginBottom: '10px' }}>
        <StripePaymentForm screenName={screenName} topModalPosition="280px" />
      </S.Form>

      <PaymentRequestOption screenName={screenName} />

      <S.PaymentServiceImage
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
        style={{ marginTop: '0' }}
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.Disclaimer>
        {t`payment.disclaimer`}&nbsp;
        <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>.&nbsp;
        {t`payment.reference`}
      </S.Disclaimer>
    </>
  )
}
