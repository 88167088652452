import amplitude from 'amplitude-js'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'
import {
  IEventLogger,
  IEventLogParams,
  TCommonEventProperties,
} from 'models/events.model'
import { EventLoggerInstanceName } from 'root-constants'

interface IAmplitude {
  getInstance: (amplitudeInstanceName?: string) => typeof amplitude
  init: (
    apiKey: string,
    userId: string | null,
    options?: Record<string, unknown>,
  ) => void
  setUserId: (userId: string) => void
  setUserProperties: (properties: Record<string, unknown>) => void
  logEvent: (
    event: string,
    eventProperty?: Record<string, unknown>,
    cb?: () => void,
  ) => void
}

export class Amplitude implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly amplitude: IAmplitude
  private utmTags: Record<string, string>
  private cohort = ''
  private readonly isProdEnvironment: boolean
  private userProperties: Record<string, unknown> = {}

  constructor({
    instanceName,
    apiKey,
    userId,
    utmTags,
    options,
    isProdEnvironment,
  }: {
    instanceName: EventLoggerInstanceName
    apiKey: string
    userId: string
    utmTags: Record<string, string>
    options?: Record<string, unknown>
    isProdEnvironment: boolean
  }) {
    this.utmTags = utmTags
    this.name = instanceName
    this.amplitude = amplitude.getInstance(instanceName)
    this.amplitude.init(apiKey, userId || null, options)
    this.isProdEnvironment = isProdEnvironment
  }

  log({ event, eventProperty, cb }: IEventLogParams): void {
    const mergedEventProperty: TCommonEventProperties = {
      cohort: this.cohort,
      attribution: this.utmTags,
      operating_system_version: getOperatingSystemVersion(),
      is_prod_env: this.isProdEnvironment,
      ...eventProperty,
    }

    this.amplitude.logEvent(event, mergedEventProperty, cb)
  }

  configure({
    cohort,
    options,
  }: {
    cohort: string
    options?: Record<string, string>
  }): void {
    this.cohort = cohort
    this.setUserProperties(options)
  }

  updateUserProperties(props: Record<string, any>): void {
    const updatedUserProperties = {
      ...this.userProperties,
      ...props,
    }

    this.userProperties = updatedUserProperties
    this.amplitude.setUserProperties(updatedUserProperties)
  }

  private setUserProperties(options?: Record<string, string>): void {
    const userProperties = {
      'Device Type': getMobileOperatingSystem(),
      'Operating System Version': getOperatingSystemVersion(),
      attribution: this.utmTags,
      ...options,
    }

    this.userProperties = userProperties
    this.amplitude.setUserProperties(userProperties)
  }
}
