import checkAnimation from 'assets/animation/checkAnimation.json'
import lottie from 'lottie-web/build/player/lottie_light'
import { SvgImage } from 'components/SvgImage'
import arrow from 'assets/images/sprite/white-right-arrow.svg'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectEmail } from 'root-redux/user/user-selects'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { CONTACT_FORM_LINKS } from 'root-constants'
import { StyledPaymentStatus as S } from './PaymentStatus.styles'

type TProps = {
  animationContainerRef: React.MutableRefObject<HTMLDivElement>
  onContinue: () => void
}

export const PaymentSuccessCs: React.FC<TProps> = ({
  animationContainerRef,
  onContinue,
}) => {
  const email = useSelector(selectEmail)
  const env = getCurrentEnv()

  useEffect(() => {
    if (animationContainerRef.current) {
      const animation = lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: checkAnimation,
        loop: true,
      })
      animation.addEventListener('loopComplete', () => {
        animation.goToAndStop(2000) // end of animation loop
      })
    }

    return () => lottie.destroy()
  }, [animationContainerRef])

  return (
    <S.Wrapper>
      <S.Container>
        <div ref={animationContainerRef} style={{ width: 100 }} />
        <S.Title>Thank you for your purchase!</S.Title>
        <S.Subtitle>
          Check the email you provided - <span>{email}</span>
          &nbsp;it contains the details about your order.
        </S.Subtitle>
        <S.StickyBlockWrapper>
          <S.Button onClick={onContinue}>
            <S.ButtonText>Continue shopping</S.ButtonText>
            <SvgImage svg={arrow} width={24} />
          </S.Button>
          <S.SupportBlock>
            Wrong email or didn&apos;t receive the email? <br />
            Contact our support&nbsp;
            <S.InnerLink target="_blank" to={CONTACT_FORM_LINKS[env]}>
              here
            </S.InnerLink>
            .
          </S.SupportBlock>
        </S.StickyBlockWrapper>
      </S.Container>
    </S.Wrapper>
  )
}
