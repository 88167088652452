import firebase from 'firebase/compat/app'
import { Amplitude } from 'services/amplitude-service'
import {
  COHORT_KEYS_MAP,
  CurrentEnvironment,
  EventLoggerInstanceName,
} from 'root-constants'
import { eventLogger } from 'services/eventLogger-service'
import { getCurrentEnv } from './getCurrentEnv'
import { getMeowzAbTestOptions } from './getMeowzAbTestOptions'

export const addAmplitudeLogger = ({
  amplitudeApiKey,
  uuid,
  URLSearchString,
  cohort,
  language,
  firebaseRemoteConfig,
}: {
  amplitudeApiKey: string
  uuid: string
  URLSearchString: string
  cohort: string
  language: string
  firebaseRemoteConfig: firebase.remoteConfig.RemoteConfig | null
}): void => {
  const URLParams = new URLSearchParams(URLSearchString)
  const utmTags = Object.fromEntries(URLParams.entries())
  const isProdEnvironment = getCurrentEnv() === CurrentEnvironment.PROD

  const amplitudeService = new Amplitude({
    apiKey: amplitudeApiKey,
    instanceName: EventLoggerInstanceName.AMPLITUDE,
    userId: uuid,
    utmTags,
    isProdEnvironment,
  })

  const meowzAbTestOptions = getMeowzAbTestOptions({
    cohort,
    language,
    remoteConfigVariantValue:
      firebaseRemoteConfig?.[COHORT_KEYS_MAP[cohort]]?._value,
  })

  amplitudeService.configure({
    cohort,
    options: meowzAbTestOptions || {},
  })
  eventLogger.addLoggers([amplitudeService])
}
