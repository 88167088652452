import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'src/constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { BodyLanguageModal } from 'pages/questions/modals/BodyLanguageModalMessage'
import { Trans } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'
import { BODY_LANGUAGE_ABTEST_OPTIONS_VALUES } from '../../constants/options-constants'

const ABTEST_OPTIONS = [
  { value: BODY_LANGUAGE_ABTEST_OPTIONS_VALUES.YES, text: 'Yes, I do it well' },
  { value: BODY_LANGUAGE_ABTEST_OPTIONS_VALUES.SOMETIMES, text: 'Sometimes' },
  { value: BODY_LANGUAGE_ABTEST_OPTIONS_VALUES.NO, text: 'Not really' },
]

const QUESTION = 'Do you understand your cat`s body language?'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const [isModalShown, setIsModalShown] = useState(false)
  const [answer, setAnswer] = useState('')

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  const openModal = useCallback((value: string) => {
    setAnswer(value)
    setIsModalShown(true)
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>
            <Trans
              i18nKey="onboarding.bodyLanguage.question"
              components={[<br />]}
            />
          </S.Title>

          <StyledOption.OptionsContainer>
            {ABTEST_OPTIONS.map((option) => (
              <S.Option
                {...optionProps}
                onChange={openModal}
                value={option.value}
                key={option.value}
              >
                <QuestionButton>{option.text}</QuestionButton>
              </S.Option>
            ))}
          </StyledOption.OptionsContainer>
        </S.Content>
      </S.Wrapper>

      <BodyLanguageModal
        onClose={() => {
          setIsModalShown(false)
          handleContinue(answer)
        }}
        isShown={isModalShown}
      />
    </>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.BODY_LANGUAGE_2

export const BodyLanguageQuestionVariant2 = Component
