import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LOCALES, TimeInterval } from 'root-constants'
import {
  DAYS_IN_PERIOD,
  PeriodCountByDays,
} from 'modules/subscriptions/constants'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export const PlanItemVariant2: React.FC<ITrialSelectPlanItemProps> = ({
  subscription: { durationDays, daily, fullPrice, oldPrices },
  currency,
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t, i18n } = useTranslation()

  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  return (
    <S.PlanItem
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.PlanTitle isLongLocale={isLongLocale}>
        {durationDays === DAYS_IN_PERIOD[TimeInterval.WEEK] ? (
          <Trans
            i18nKey="onboarding.planBlock.planPeriod"
            values={{ periodQuantity: durationDays }}
          />
        ) : (
          <Trans
            i18nKey="onboarding.planBlock.planPeriod2"
            values={{ periodQuantity: PeriodCountByDays[durationDays] }}
          />
        )}
        <S.OldPricesWrapper>
          <S.StartOldPrice>
            {currency}
            {oldPrices.fullPrice}
          </S.StartOldPrice>
          <S.CurrentPrice>
            {currency}
            {fullPrice}
          </S.CurrentPrice>
        </S.OldPricesWrapper>
      </S.PlanTitle>
      <S.PricesWrapper>
        {daily && (
          <S.PricePerPeriod isSelected={isSelected} isDefault={isDefault}>
            <S.OldIntervalPrice>
              {currency}
              {oldPrices.daily}
            </S.OldIntervalPrice>
            <strong>
              {currency}
              {daily}
            </strong>
            {t`onboarding.planBlock.per`}&nbsp;
            {t`onboarding.planBlock.day`}
          </S.PricePerPeriod>
        )}
      </S.PricesWrapper>
    </S.PlanItem>
  )
}
