import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeInterval } from 'root-constants'
import { DAYS_IN_PERIOD } from 'modules/subscriptions/constants'
import { useSelector } from 'react-redux'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export const PlanItemVariant3Currency: React.FC<ITrialSelectPlanItemProps> = ({
  subscription: { durationDays, daily, fullPrice, oldPrices },
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrencySign)

  return (
    <S.PlanItem
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.PlanTitle>
        {durationDays === DAYS_IN_PERIOD[TimeInterval.WEEK] ? (
          <>
            {durationDays / 7}-Week Trial
            <S.TrialBadge>4-WEEK PLAN</S.TrialBadge>
          </>
        ) : (
          `${durationDays / 7}-Week Plan`
        )}
        <S.OldPricesWrapper>
          <S.StartOldPrice>
            {currency}
            {oldPrices.fullPrice}
          </S.StartOldPrice>
          <S.CurrentPrice>
            {currency}
            {fullPrice}
          </S.CurrentPrice>
        </S.OldPricesWrapper>
      </S.PlanTitle>
      <S.PricesWrapper>
        {daily && (
          <S.PricePerPeriod isSelected={isSelected} isDefault={isDefault}>
            <S.OldIntervalPrice>
              {currency}
              {oldPrices.daily}
            </S.OldIntervalPrice>
            <strong>
              {currency}
              {daily}
            </strong>
            {t`onboarding.planBlock.per`}&nbsp;
            {t`onboarding.planBlock.day`}
          </S.PricePerPeriod>
        )}
      </S.PricesWrapper>
    </S.PlanItem>
  )
}
