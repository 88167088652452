import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js'
import { Button } from 'components/buttons/Button'
import { FlowContext } from 'components/FlowContext'
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { EMPTY_FIELD_ERROR, StripeFieldName } from 'root-constants'
import { useTranslation } from 'react-i18next'
import { resetErrorAction } from 'root-redux/common/common-actions'
import { THREEDS_REDIRECT_SEARCH_PARAM } from 'modules/payment/constants'
import { TCreditCardField, TPaymentErrorState } from 'modules/payment/types'
import {
  select3DSecureIframeUrl,
  selectCurrency,
} from 'root-redux/payment/payment-selects'
import {
  check3DSecure,
  purchaseAction,
} from 'root-redux/payment/payment-actions'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/payment/helpers/general'
import { PaymentModal } from 'components/modals/PaymentModal'
import {
  stripeElementStyle,
  StyledStripePaymentForm as S,
} from './StripePaymentForm.styles'

interface IModalProps {
  topModalPosition?: string
  screenName?: string
}

export const StripePaymentForm: React.FC<IModalProps> = ({
  topModalPosition,
  screenName,
}) => {
  const { currentPageId } = useContext(FlowContext)
  const { search } = useLocation()
  const stripe = useStripe()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const currency = useSelector(selectCurrency)
  const elements = useElements()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [isModalShown, setIsModalShown] = useState(false)
  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: StripeFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card || !currentPageId) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name: '',
          paymentPageId: currentPageId,
          currency,
          screenName,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      currentPageId,
      errors,
      currency,
      screenName,
    ],
  )

  return (
    <>
      <S.Form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <S.InputsContainer style={{ marginBottom: '0', width: '100%' }}>
          <S.CardNumberContainer
            style={{ width: '100%' }}
            data-has-label
            data-invalid={!!errors[StripeFieldName.NUMBER].error}
          >
            <S.Label>{t`stripeForm.cardNumber`}</S.Label>
            <S.CardNumberElement
              onReady={(elem) => {
                elem.focus()
                cardNumberElemRef.current = elem
              }}
              options={{
                showIcon: false,
                placeholder: '1234 1234 1234 1234',
                style: stripeElementStyle,
              }}
              onChange={({ empty, error, complete }) => {
                handleChange({
                  fieldName: StripeFieldName.NUMBER,
                  isEmpty: empty,
                  hasError: !!error,
                  isComplete: complete,
                  nextElemRef: cardExpiryElemRef,
                })
              }}
            />
          </S.CardNumberContainer>
          <S.CvvExpiryInputContainer style={{ gap: '8px' }}>
            <S.CardExpiryContainer
              style={{ width: '100%' }}
              data-invalid={!!errors[StripeFieldName.EXPIRY].error}
              data-has-label
            >
              <S.Label>{t`stripeForm.cardExpiry`}</S.Label>
              <S.CardExpiryElement
                onReady={(elem) => {
                  cardExpiryElemRef.current = elem
                }}
                options={{
                  placeholder: t`stripeForm.expiryDate`,
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: StripeFieldName.EXPIRY,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cvcElemRef,
                  })
                }}
              />
            </S.CardExpiryContainer>

            <S.CardCvvContainer
              data-has-label
              data-invalid={!!errors[StripeFieldName.CVC].error}
              style={{ width: '100%' }}
            >
              <S.CardCvcElementContainer>
                <S.Label>
                  <span>{t`stripeForm.securityNumber`}</span>
                  <S.PromptButton onClick={() => setIsModalShown(true)}>
                    ?
                  </S.PromptButton>
                </S.Label>
                <S.CardCvcElement
                  onReady={(elem) => {
                    cvcElemRef.current = elem
                  }}
                  options={{
                    placeholder: '***',
                    style: stripeElementStyle,
                  }}
                  onChange={({ empty, error, complete }) => {
                    handleChange({
                      fieldName: StripeFieldName.CVC,
                      isEmpty: empty,
                      hasError: !!error,
                      isComplete: complete,
                    })
                  }}
                />
              </S.CardCvcElementContainer>
            </S.CardCvvContainer>
          </S.CvvExpiryInputContainer>
        </S.InputsContainer>
        {errors[StripeFieldName.NUMBER].error && (
          <S.ErrorText>{t`stripeForm.errors.number`}</S.ErrorText>
        )}
        {errors[StripeFieldName.EXPIRY].error && (
          <S.ErrorText>{t`stripeForm.errors.expiry`}</S.ErrorText>
        )}
        {errors[StripeFieldName.CVC].error && (
          <S.ErrorText>{t`stripeForm.errors.CVC`}</S.ErrorText>
        )}
        <Button
          type="submit"
          disabled={!stripe || !isFormValid}
          style={{ margin: '8px 0' }}
        >
          {t`actions.continue`}
        </Button>
      </S.Form>
      <PaymentModal
        topModalPosition={topModalPosition}
        onClose={() => {
          setIsModalShown(false)
        }}
        isShown={isModalShown}
      />
    </>
  )
}
